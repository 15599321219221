import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { v4 } from 'uuid';

import Content, { HTMLContent } from '../components/Content';
import BannerHeader from '../components/BannerHeader';
import Layout from '../components/Layout';
import ServicesHero from '../components/ServicesHero';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import ReviewBar from '../components/ReviewBar';
import ContactCallToAction from '../components/ContactCallToAction';
import TestimonialCarousel from '../components/TestimonialCarousel';
import SEO from '../components/SEO';
import TeamMember from '../components/TeamMember';

export const AboutPageTemplate = ({
  header,
  content,
  contentComponent,
  contentImage,
  badges,
  teamMembers,
}) => {
  const PageContent = contentComponent || Content;

  return (
    <div>
      <SEO title="About Us" pathname="/moving-company" />
      <BannerHeader
        title={header.title}
        subtitle={header.subtitle}
        image={header.image}
      />
      <section className="section">
        <div className="container content markdown-content">
          <div className="columns is-multiline">
            <div className="column is-6 is-left-about-image is-hidden-mobile ">
              <PreviewCompatibleImage imageInfo={contentImage} />
            </div>
            <div className="column is-6">
              <PageContent className="markdown-content" content={content} />
              <br />
              <div className="columns is-multiline is-mobile">
                {badges.map((badge) => {
                  if (
                    !badge ||
                    !badge.image ||
                    !badge.image.childImageSharp ||
                    !badge.image.childImageSharp.fluid
                  )
                    return null;

                  return (
                    <div key={v4()} className="column is-3">
                      <img
                        src={badge.image.childImageSharp.fluid.src}
                        alt={badge.image.childImageSharp.fluid.alt}
                      />
                    </div>
                  );
                })}
                <div key={v4()} className="column is-3">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Click for the BBB Business Review of Organized Transitions"
                    href="https://www.bbb.org/ca/on/dundas/profile/senior-move-manager/organized-transitions-0107-1372371?utm_campaign=bbb_seal&utm_content=Organized%20Transitions&utm_medium=website&utm_source=seal_click_1372371%20"
                  >
                    <img
                      alt="Click for the BBB Business Review of Organized Transitions"
                      src="https://www.bbb.org/TerminusContent/dist/img/ab-seal-vertical.svg"
                      width="60px"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container content markdown-content">
          {teamMembers.map((teamMember) => (
            <TeamMember
              key={v4()}
              title={teamMember.title}
              body={teamMember.body}
              image={teamMember.image}
            />
          ))}
        </div>
      </section>
    </div>
  );
};

AboutPageTemplate.propTypes = {
  header: PropTypes.shape({
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    title: PropTypes.string,
    subtitle: PropTypes.string,
  }),
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  badges: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    })
  ),
  ownerHeader: PropTypes.string,
  ownerBlurb: PropTypes.string,
  ownerImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

const AboutPage = ({ data }) => {
  const { markdownRemark } = data;

  return (
    <Layout>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        content={markdownRemark.html}
        {...markdownRemark.frontmatter}
      />
      <ContactCallToAction />
      <ServicesHero />
      <TestimonialCarousel />
      <ReviewBar />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "about-page" } }) {
      html
      frontmatter {
        header {
          title
          subtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        contentImage {
          childImageSharp {
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        badges {
          image {
            childImageSharp {
              fluid(maxWidth: 153, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        teamMembers {
          title
          body
          image {
            childImageSharp {
              fixed(width: 197, height: 300) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`;
