import React from 'react';
import { Link } from 'gatsby';

const ServicesHero = () => {
  return (
    <section className="hero is-primary">
      <div className="hero-body">
        <div className="container">
          <div className="columns has-text-centered">
            <div className="column is-4">
              <Link
                to="/services/estate-clear-out"
                className="has-text-white is-size-5"
              >
                Estate Clear Outs
              </Link>
            </div>
            <div className="column is-4">
              <Link
                to="/services/move-management"
                className="has-text-white is-size-5"
              >
                Downsizing / Move Management
              </Link>
            </div>
            <div className="column is-4">
              <Link
                to="/services/auction-management"
                className="has-text-white is-size-5"
              >
                Auction Management
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesHero;
