import React from 'react';
import PropTypes from 'prop-types';

import Img from 'gatsby-image';

const TeamMember = ({ title, body, image }) => {
  return (
    <div className="columns">
      <div className="column is-9">
        <h1>{title}</h1>
        <p>{body}</p>
      </div>
      <div className="column is-3 has-text-centered">
        {image && image.childImageSharp && (
          <Img fixed={image.childImageSharp.fixed} />
        )}
      </div>
    </div>
  );
};

TeamMember.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  image: PropTypes.object
};

export default TeamMember;
